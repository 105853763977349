import {
  AcuityAppointmentTag,
  crmClient,
  LeadStatus,
  MixpanelClient,
  MixpanelEvent,
  onboardingClient,
  ProfileStatus,
  ProviderRole,
  reportErrorToHoneybadger,
  scheduleClient,
  userClient,
} from '@enaratech/funnel-helper';
import Toast from 'src/components/Common/Toast/Toast';
import { SET_SSB_INFO, SSBFlowState } from 'src/contexts/ssb/types';
import { randomElementFromArray } from 'src/utils/array';
import { getDataFromTimeSlotKey } from '../../appointments';
import { AppointmentCreatedActionParams } from '../types';

export const createAppointmentAction = async ({
  context,
  setters,
  setState,
}: AppointmentCreatedActionParams) => {
  const {
    selectedTimeSlotKey,
    availableProviders,
    scheduledAppointments,
    stepsRef,
    user,
    routePath,
  } = context;

  const {
    setAvailableProviders,
    setIsSubmitting,
    setScheduledAppointments,
    setSelectedLocation,
    setSelectedTimeSlotKey,
  } = setters;

  if (!selectedTimeSlotKey) {
    return Toast.notification('error', 'Please, select date and time of the appointment');
  }

  setIsSubmitting(true);

  if (!availableProviders || !(availableProviders.length > 0) || !stepsRef.current) {
    const error = {
      message: 'We could not create the appointment',
      steps: stepsRef.current,
      availableProviders,
      selectedTimeSlotKey,
    };
    Toast.notification('error', error.message);
    return reportErrorToHoneybadger({
      error: JSON.stringify(error, null, 3),
    });
  }

  const currentStep = stepsRef.current.find((s) => s.active);

  if (!currentStep) {
    reportErrorToHoneybadger({
      error: `[createAppointmentAction] There is not a current step for this user. ${currentStep}`,
      isReportable: true,
    });
    return null;
  }

  const { allowedSpecialty } = currentStep.config;
  const { dateTime, acuityCalendarIds, acuityAppointmentTypeId } =
    getDataFromTimeSlotKey(selectedTimeSlotKey);

  const randomAcuityCalendarId = randomElementFromArray<number>(acuityCalendarIds);

  const randomProvider = availableProviders.filter(
    (ap) => ap.acuityCalendarId === randomAcuityCalendarId
  )[0];

  if (!randomProvider) {
    reportErrorToHoneybadger({
      error: `randomProvider is undefined. Data: ${JSON.stringify(
        { acuityCalendarIds, randomAcuityCalendarId, availableProviders },
        null,
        3
      )}`,
      isReportable: true,
    });
  }

  const appointmentCreated = await scheduleClient.createAppointment({
    calendarId: randomProvider.acuityCalendarId,
    appointmentTypeId: acuityAppointmentTypeId,
    dateTime,
    tag: AcuityAppointmentTag.FunnelAppSSB,
  });

  if (!appointmentCreated) {
    setIsSubmitting(false);

    return Toast.notification('error', 'Could not create appointment');
  }

  const { date, endTime, datetime, timezone, location, type } = appointmentCreated;

  if (allowedSpecialty === ProviderRole.MedicalProvider) {
    const userWasUpdated = await userClient.updateUser({
      initialAppointmentId: appointmentCreated.id,
    });

    if (userWasUpdated) {
      userClient.activateUser();
    }

    userClient.setProfileStatus(ProfileStatus.Exploring);

    crmClient.updateLead({
      uuid: user!.uuid,
      startDate: appointmentCreated.datetime,
      medicalProviderName: `${randomProvider.firstName} ${randomProvider.lastName}`,
    });

    onboardingClient.updateMemberStatus({
      crm: {
        leadStatus: LeadStatus.InitialBooked,
        bookingData: { datetime: appointmentCreated.datetime },
      },
    });

    MixpanelClient.setMetadata({ ssb: true });
  }

  MixpanelClient.setMetadata({
    initials: Object.keys(scheduledAppointments!).join(','),
  });

  MixpanelClient.trackEvent({
    event: MixpanelEvent.InputData,
    properties: {
      field: 'Appointment created',
      value: currentStep.details.specialty,
      source: routePath,
    },
  });
  const appointmentCode = type.match(/[A-Z]+(-[A-Z]+)+/g)![0];

  const recentlyFetchedPreviousAppointments = {
    ...scheduledAppointments!,
    [appointmentCode]: {
      date,
      endTime,
      datetime,
      timezone,
      location,
    },
  };

  setAvailableProviders(null);
  setSelectedTimeSlotKey(null);
  setSelectedLocation(undefined);
  setScheduledAppointments(recentlyFetchedPreviousAppointments);
  setIsSubmitting(false);

  /**
   * This is to wait for updates in core db regarding scheduling
   */
  setters.setSelectedLanguage(null);
  setTimeout(() => setState({ type: SET_SSB_INFO, payload: SSBFlowState.GoToNextStep }), 1000);
};
